import { addDays, eachDayOfInterval, format, isAfter, isBefore, isSameDay, parse } from "date-fns";
import React, { createRef, useEffect, useState, useContext, useCallback } from "react";
import styled from "styled-components";

import Back from "../ui/Back";
import Button from "../ui/Button";
import ButtonGroup from "../ui/ButtonGroup";
import CrossSell from "./CrossSell/CrossSell";
import { dateFormat, weekdays } from "../../helper";
import Divider from "../ui/Divider";
import EndTimeField from "../ui/EndTimeField";
import Errors from "../ui/Errors";
import Field from "../ui/Field";
import Flatpickr from "../ui/Flatpickr";
import Flex from "../ui/Flex";
import getOffersRoundel from "./utils/getOffersRoundel";
import Grid from "../ui/Grid";
import GridWrap from "../ui/GridWrap";
import GuestField from "../ui/GuestField";
import Package from "../ui/Package";
import StartTimeField from "../ui/StartTimeField";
import { tableStore } from "../../store/table";
import Text from "../ui/Text";
import { scrollIntoView } from "scroll-js";
import { getParams } from "../../helper";

const Tab = styled(Button)`
  margin-right: -0.5px;
  margin-left: -0.5px;
  padding: 20px 5px;
  background: ${(props) =>
    props.active ? (props.isChristmas && '#7f1b1b') || "var(--colour-tertiary-dmn)" : (props.isChristmas && '#7f1b1b') || "transparent"};
  border-color: ${(props) =>
    props.active ? (props.isChristmas && 'white') || "var(--colour-tertiary-dmn)" : "white"};

    @media (max-width: 500px) {
      padding: 10px 3px;
      font-size: 12px;
    }
`;

const ListItem = styled("li")`
  list-style-type: none;
  color: rgb(220 0 0);
  font-size: 13px;
  margin-bottom: 5px;
`

const Start = ({ modalRef }) => {
  const gridRef = createRef();

  const today = (new Date()).toISOString().split("T")[0];

  const { isActive, table, tableDispatch, setup, actions } =
    useContext(tableStore);

  const { stepCache: { start: cache = { activeOption: false, categories: [] } } } = table;

  const [activeOption, setActiveOption] = useState(cache.activeOption);
  const [options, setOptions] = useState(cache.categories);

  const params = getParams(window.location.search);

  const getBookingTypeValues = (id, bookingTypes) => {
    if (id && typeof bookingTypes === "object") {
      const bookingType = bookingTypes.find(({ id: bookingTypeId }) => bookingTypeId === id);

      if (bookingType) {
        const { overrides, packageMandatory, rules } = bookingType;

        return { overrides, packageMandatory, rules };
      }
    }

    return {};
  };

  const setBookingType = (data, bookingTypes) => {
    const { overrides, packageMandatory, rules } = getBookingTypeValues(data.id, bookingTypes || activeOption.bookingTypes);

    tableDispatch([
      { type: "SET_API_ENABLED", data: false },
      { type: "SET_BOOKING_TYPE", data },
      {
        type: "SET_MANDATORY_PACKAGES",
        data: packageMandatory
      },
      {
        type: "SET_OVERRIDES",
        data: overrides
      },
      { type: "SET_PACKAGES", data: data.packages },
      {
        type: "SET_RULES",
        data: rules
      }
    ]);

    scrollIntoView(gridRef.current, modalRef.current, { duration: 500, easing: "ease-in-out" });
  };

  useEffect(() => {
    if (params.booking_type_category && options.length) {
      setActiveOption(options.find(option => option.id == params.booking_type_category) || options[0]);
    }
  }, [params, options]);

  const load = useCallback(() => {
    fetch(`${setup.endpoint}/v1/venue/${table.venueId}/setup`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.categories) setOptions(data.categories);
        if (data?.categories?.length) {
          if (typeof table.bookingTypeCategory !== "undefined") {
            const category = data.categories.find(({ id }) => id === Number(table.bookingTypeCategory)) || data.categories[0];
            if (category) {
              setActiveOption(category);

              if (typeof table.bookingType !== "undefined") {
                const { bookingTypes } = category;
                const bookingType = bookingTypes.find(({ id }) => id === table.initialBookingType);

                if (bookingType) {
                  setBookingType(bookingType, bookingTypes);
                }
              }
            }
          } else {
            setActiveOption(data.categories[0]);
          }
        }
      });
  }, [setBookingType, setup.endpoint, table, table.bookingTypeCategory, table.venueId]);

  useEffect(() => {
    if (!activeOption && !options.length) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlertText = (id, option) => {
    if (id && typeof option.bookingTypes === "object") {
      const bookingType = option.bookingTypes.find(({ id: bookingTypeId }) => bookingTypeId === id);

      if (bookingType) {

        if (((!bookingType.alertText || bookingType.alertText === null) && !option.title.toLowerCase().includes("christmas") && bookingType.showDefaultAlertText)) {
          return "Please note we keep an allocation of tables available for walk ins on the day so do pop by and we will always try and accommodate you.";
        }
        return bookingType.alertText;
      }


    }

    return "";
  }

  const formatClosureDates = (from, to) => {

    if (!from && !to) return "";

    if (from === to) {
      return format(new Date(from), "do MMMM yyyy")
    }

    return `${format(new Date(from), "do")} - ${format(new Date(to), "do MMMM yyyy")}`

  }

  const getClosureMessages = (id, option) => {
    if (id && typeof option.bookingTypes === "object") {

      const bookingType = option.bookingTypes.find(({ id: bookingTypeId }) => bookingTypeId === id);

      if (bookingType && bookingType.overrides.length > 0) {
        return bookingType.overrides
      }

    }
    return "";

  }

  const alertText = getAlertText(table.bookingType, activeOption);
  const closureMessages = getClosureMessages(table.bookingType, activeOption);

  const getEnabledDates = useCallback((overrides = table.overrides, rules = table.rules) => {
    const alwaysEnabled = new Set();
    const alwaysDisabled = new Set();

    overrides.forEach((override) => {
      const { available } = override;

      const overrideDates = eachDayOfInterval({ start: new Date(`${override.dateFrom}T00:00:00`), end: new Date(`${override.dateTo}T23:59:59`) });

      if (available) {
        overrideDates.forEach((date) => alwaysEnabled.add(format(date, "yyyy-MM-dd")));
      } else {
        overrideDates.forEach((date) => alwaysDisabled.add(format(date, "yyyy-MM-dd")));
      }
    });

    const weekdayIndexes = [];

    Object.values(rules).forEach((rule) => {
      const { available, dayOfWeek } = rule;

      if (available && dayOfWeek) {
        const weekdayIndex = weekdays.findIndex((weekday) => weekday === dayOfWeek);

        if (weekdayIndex > -1) {
          weekdayIndexes.push(weekdayIndex);
        }
      }
    });

    return [
      (date) => {

        const dateStr = format(date, "yyyy-MM-dd");

        if (!alwaysDisabled.has(dateStr) && (alwaysEnabled.has(dateStr) || weekdayIndexes.includes(date.getDay()))) {
          return true;
        }

        return false;
      },
    ];
  }, [table])

  useEffect(() => {
    if (!isActive || !table.date) return;
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Selection",
      eventLabel: `Date - ${table.date}`,
    });

  }, [isActive, table.date]);

  const handleBack = () => {
    return tableDispatch([
      { type: "PREVIOUS_STEP" },
      { type: "SET_STEP_CACHE", data: { start: undefined } }
    ]);
  };

  const handleCategoryChange = (option) => {
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Selection",
      eventLabel: `Category - ${option.title}`,
    });

    if (params.booking_type_category) {
      const url = new URL(window.location);
      url.searchParams.set("booking_type_category", option.id);
      window.history.replaceState(null, "", url);
    }

    setActiveOption(option);
  }

  const handleDateChange = (date) => {
    tableDispatch([
      { type: "SET_API_ENABLED", data: true },
      ...(typeof date !== "undefined" ? [{ type: "SET_DATE", data: date }] : []),
    ]);
  };

  const handleGuestsChange = ({ guests, nonInterraction = false }) => {
    if (!nonInterraction) {
      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `Guests - ${guests}`,
      });
    }

    tableDispatch({
      type: "SET_GUESTS",
      data: guests,
    });
  };

  const handleTimeStartChange = ({ time, nonInterraction = false }) => {
    if (!nonInterraction) {
      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `Start Time - ${time}`,
      });
    }

    const currentTimeslot = table.timeslots.find((timeslot) => timeslot.time === time) || { availableOffers: [] };

    tableDispatch([
      { type: "SET_AVAILABLE_OFFERS", data: currentTimeslot.availableOffers },
      { type: "SET_TIME", data: time }
    ]);
  };

  const handleDurationChange = ({ duration, nonInterraction = false }) => {
    if (!nonInterraction) {
      const [h, m] = table.time.split(":");
      const totalHours = Number(h) + Math.floor(duration / 60);
      const totalMinutes = Number(m) + (Number(duration) % 60);

      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `End Time - ${totalHours
          .toString()
          .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`,
      });
    }

    tableDispatch({
      type: "SET_DURATION",
      data: duration,
    });
  };

  const handleGetAvailability = () => {
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Submit",
      eventLabel: "Check Availability",
    });

    // Six Nations PPC Conversion
    if (
      table?.bookingTypeTitle &&
      typeof window.gtag !== "undefined"
    ) {
      if (table?.bookingTypeTitle.toLowerCase().includes("six nation")) {
        window.gtag("event", "conversion", { "send_to": "AW-757210388/ZXbZCMqPyZUDEJS6iOkC" });
      } else {
        window.gtag('event', 'conversion', { 'send_to': 'AW-757210388/gcTKCMrF7ZsDEJS6iOkC' });
      }
    }

    tableDispatch(
      {
        type: "SET_STEP_CACHE",
        data: { start: { activeOption, categories: options } }
      }
    );

    actions.checkAvailability();
  };

  const dateMeetsOverrides = useCallback((date, defaultStatus) => {

    const openOverrides = table.overrides.filter(override => (override.available))
    const closedOverrides = table.overrides.filter(override => (!override.available))

    const openOverridesMet = openOverrides.filter(override => {
      const from = parse(override.dateFrom, "yyyy-MM-dd", new Date());
      const to = parse(override.dateTo, "yyyy-MM-dd", new Date());

      return (
        (isSameDay(from, date) || isAfter(date, from)) &&
        (isSameDay(from, to) || isBefore(date, to))
      );
    })

    const closedOverridesMet = closedOverrides.filter(override => {
      const from = parse(override.dateFrom, "yyyy-MM-dd", new Date());
      const to = parse(override.dateTo, "yyyy-MM-dd", new Date());

      return (
        isAfter(date, from) && isBefore(date, to)
      );
    });

    if (defaultStatus && closedOverridesMet.length === 0) {
      return true;
    }

    if (!defaultStatus && openOverridesMet.length > 0) {
      return true;
    }
    return false;

  }, [table])

  const getStartDate = useCallback(() => {

    if (table.date || (!table.overrrides && !table.rules)) return null;

    let count = 0;
    let checkDate = new Date();
    let selected = null;

    const getIsDisabled = getEnabledDates(table.overrides, table.rules)[0];


    while (!selected && count < 365) {
      const defaultStatus = getIsDisabled(checkDate);
      const meetsOverides = dateMeetsOverrides(checkDate, defaultStatus);

      if (meetsOverides) {
        selected = checkDate
      }

      count += 1
      checkDate = addDays(checkDate, 1)
    }

    if (!selected) {
      selected = new Date()
    }

    return selected;
  }, [table])

  return (
    <>
      {setup.venueId === "corporate" && <Back onClick={handleBack}><span>Back</span></Back>}
      <Flex paddingRight="0.5px">
        {options &&
          Array.from(options, (option) => (
            <Tab
              key={option.title}
              active={activeOption.title === option.title}
              onClick={() => handleCategoryChange(option)}
              isChristmas={option.title === "Christmas"}
              data-id={option.id}
            >
              {option.title}
            </Tab>
          ))}
      </Flex>
      {activeOption && activeOption.message && (
        <Text>
          {activeOption.message}
        </Text>
      )}
      <Divider />
      <GridWrap>
        {activeOption &&
          activeOption.bookingTypes &&
          Array.from(activeOption.bookingTypes, (data) => (
            <Package
              active={table.bookingType === data.id}
              click={setBookingType}
              data={data}
              key={data.id}
              roundel={getOffersRoundel(data.id, activeOption?.offers)}
            />
          ))}
      </GridWrap>
      <Divider />
      {alertText && (
        <Text>
          {alertText}
        </Text>
      )}

      {closureMessages && (
        <ul>
          {closureMessages.map(override => (
            override.message && (
              <ListItem>{`${formatClosureDates(override.dateFrom, override.dateTo)}: ${override.message}`}</ListItem>
            )
          ))}
        </ul>
      )}
      <Grid mobileFullWidth ref={gridRef}>
        <div>
          <Field label="Date" value={(dateFormat(table.date) ? dateFormat(table.date) : 'Select Date')} calendarAbove>
            <Flatpickr
              defaultDate={table.date}
              enable={getEnabledDates(table.overrides, table.rules)}
              handleChange={handleDateChange}
              minDate={today}
              redraw={table.bookingType}
              startAtDate={getStartDate()}
            >
              <input type="text" />
            </Flatpickr>
          </Field>
        </div>
        <div>
          <GuestField
            date={table.date}
            overrides={table.overrides}
            rules={table.rules}
            handleGuestsChange={handleGuestsChange}
            value={table.numPeople}
          />
        </div>
        <div>
          <StartTimeField
            apiEnabled={table.apiEnabled}
            bookingTypeId={table.bookingType}
            date={table.date}
            endpoint={setup.endpoint}
            numPeople={table.numPeople}
            rules={table.rules}
            tableDispatch={tableDispatch}
            timeslots={table.timeslots}
            value={table.time}
            venueId={table.venueId}
            handleTimeStartChange={handleTimeStartChange}
          />
        </div>
        <div>
          <EndTimeField
            date={table.date}
            endTimeslots={table.endTimeslots}
            maxDuration={table.maxDuration}
            minDuration={table.minDuration}
            tableDispatch={tableDispatch}
            time={table.time}
            value={table.duration}
            handleDurationChange={handleDurationChange}
          />
        </div>
      </Grid>
      <Errors errors={table.errors} />
      <ButtonGroup>
        <Button
          active
          disabled={
            table.loading
            || !table.duration
            || !table.time
            || !table.date
            || !table.bookingType
          }
          onClick={handleGetAvailability}
        >
          Check Availability
        </Button>
      </ButtonGroup>
      <CrossSell
        bookingType={table.bookingType}
        bookingTypeTitle={table.bookingTypeTitle}
        crossSellTypes={table?.crossSellTypes}
        date={table.date}
        endpoint={setup.endpoint}
        isVisible={table?.crossSellEnabled && table?.isCrossSellVisible}
        numPeople={table.numPeople}
        venueId={table.venueId}
      />
    </>
  );
};

export default Start;
